import React from "react"
//
import { GetStarted } from "../../../storybook"
import { NavigationLink } from "../../../utils/navigation"

const TextBanner = ({ data: { titulo, prefixo, variante, cta1, cta2 } }) => (
  <div tw="pt-8 lg:pt-10">
    <GetStarted
      heading={titulo}
      subheading={prefixo}
      variant={variante}
      primaryAction={<NavigationLink navigation={cta1} />}
      secondaryAction={<NavigationLink navigation={cta2} />}
    />
  </div>
)

export default TextBanner
