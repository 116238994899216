/* eslint-disable react/display-name */
import React from "react"
//
import HeroCarousel from "../components/blocks/hero-carousel/hero-carousel"
import BlocksTextAndImage from "../components/blocks/blocks-text-image/blocks-text-image"
import LatestNews from "../components/blocks/latest-news/latest-news"
import TextBanner from "../components/blocks/text-banner/text-banner"
import Newsletter from "../components/blocks/newsletter/newsletter"
import InfoGrid from "../components/blocks/info-grid/info-grid"
import HeroVideoImage from "../components/blocks/hero-video-image/hero-video-image"
import InfoList from "../components/blocks/info-list/info-list"
import BlockContacts from "../components/blocks/contacts/contacts-block"
import ContactsForm from "../components/blocks/contacts/contacts-form"
import BlockText from "../components/blocks/text/text"
import HeroSimple from "../components/blocks/hero-simple/hero-simple"
import CategoriasFormacao from "../components/blocks/categorias-formacao/categorias-formacao"

const compMappings = {
  ContentfulHeroCarousel: (props) => <HeroCarousel data={props} />,
  ContentfulBlocosTextoEImagemLateral: (props) => <BlocksTextAndImage data={props} />,
  ContentfulBlocosUltimasNoticias: (props) => <LatestNews data={props} />,
  ContentfulBlocosBannerTexto: (props) => <TextBanner data={props} />,
  ContentfulBlocosNewsletter: (props) => <Newsletter data={props} />,
  ContentfulBlocosInfoGrid: (props) => <InfoGrid data={props} />,
  ContentfulHeroVideoOuImagem: (props) => <HeroVideoImage data={props} />,
  ContentfulBlocosInfoList: (props) => <InfoList data={props} />,
  ContentfulBlocosContactos: (props) => <BlockContacts data={props} />,
  ContentfulBlocosFormularioDeContacto: (props) => <ContactsForm data={props} />,
  ContentfulBlocosTextoFormatado: (props) => <BlockText data={props} />,
  ContentfulHeroTituloSimples: (props) => <HeroSimple data={props} />,
  ContentfulBlocosCategoriasDeFormacao: (props) => <CategoriasFormacao data={props} />
}

export const renderComponent = (comp, idx = 0) => {
  if (!Object.hasOwnProperty.call(compMappings, comp.__typename)) {
    console.error(`Component ${comp.__typename} not found!`)
    return null
  }

  const MappedComponent = compMappings[comp.__typename]

  return <MappedComponent key={`${comp.id}-${idx}`} {...comp} />
}
