import React from "react"
import tw from "twin.macro"
import "twin.macro"
//
import TextBlock from "../../../storybook/components/blocks/TextBlock"
import { Container, ContentWithPaddingXl } from "../../../storybook/components/misc/Layouts"
import RichText from "../../rich-text"

const InnerContainer = tw(ContentWithPaddingXl)`pb-2 pt-6 lg:pb-3 lg:pt-8`

const BlockText = ({ data: { textoFormatado } }) => (
  <Container>
    <InnerContainer>
      <TextBlock text={<RichText data={textoFormatado} />} />
    </InnerContainer>
  </Container>
)

export default BlockText
