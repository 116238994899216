import React from "react"
// import "twin.macro"
//
import { InfoNewsBlock } from "../../../storybook"
import { NavigationLink } from "../../../utils/navigation"
import LatestNewsBlock from "../../news"

const LatestNews = ({ data: { titulo, numeroDeNoticias, cta } }) => (
  <div tw="pt-8 lg:pt-10">
    <InfoNewsBlock
      heading={titulo}
      news={<LatestNewsBlock total={numeroDeNoticias} />}
      primaryAction={<NavigationLink navigation={cta} />}
    />
  </div>
)

export default LatestNews
