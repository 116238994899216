import React from "react"
//
import { InfoListBlock } from "../../../storybook"
import { NavigationLink } from "../../../utils/navigation"
import { ImageFixed } from "../../../utils/image"
import RichText from "../../rich-text"

const InfoList = ({ data: { titulo, sumario, blocos } }) => (
  <div tw="pt-8 lg:pt-10">
    <InfoListBlock
      heading={titulo}
      description={sumario?.sumario}
      cards={blocos?.map((block) => {
        return {
          id: block?.id,
          title: block?.titulo,
          description: <RichText data={block?.descricao} />,
          image: <ImageFixed image={block?.imagem} alt={block?.titulo} />,
          cta: <NavigationLink navigation={block?.cta} />
        }
      })}
    />
  </div>
)

export default InfoList
