import React from "react"
import tw, { styled } from "twin.macro"
//
import { ImageFluid } from "../../../utils/image"
import InfoGridBlock from "../../../storybook/components/blocks/InfoGridBlock"
import { NavigationLink } from "../../../utils/navigation"

const StyledNavigationLink = styled(NavigationLink)`
  ${tw`mt-4 flex flex-grow justify-center w-full sm:w-auto text-sm sm:text-base py-2 rounded font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:ring shadow text-gray-100 hocus:text-gray-300 bg-secondary-500 hocus:bg-secondary-700 uppercase`}
`

const StyledImageFluid = styled(ImageFluid)`
  .gatsby-image-wrapper {
    width: 100% !important;
    max-width: 100%;
  }
`

const CategoriasFormacao = ({ data: { titulo, subTitulo, sumario, imagem, categorias } }) => {
  const cards = categorias?.map((categoria) => {
    return {
      title: categoria.titulo,
      description: categoria?.descricao?.descricao,
      image: categoria?.imagem ? <StyledImageFluid image={categoria.imagem} /> : null,
      cta: (
        <StyledNavigationLink
          navigation={{
            id: categoria.id,
            paginaInterna: { id: categoria.id, slug: `formacao/${categoria.slug}` },
            titulo: "ver mais"
          }}
        />
      )
    }
  })

  return <InfoGridBlock heading={titulo} subheading={subTitulo} description={sumario?.sumario} cards={cards} imageTop />
}

export default CategoriasFormacao
