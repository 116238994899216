import React from "react"
//
import { ContactsFormBlock } from "../../../storybook"

const submit = async (data) => {
  const submission = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ...data
    })
  }

  const result = await fetch(process.env.CONTACTS_SUBMISSION_URL, submission)
    .then((response) => response.status)
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
  return result
}

const BlockContacts = ({
  data: { titulo, termosECondicoes, labelNome, labelEmail, labelMensagem, mensagemDeSucesso, mensagemDeErro }
}) => (
  <div tw="pt-8 lg:pt-10 mb-12">
    <ContactsFormBlock
      title={titulo}
      labels={{ termsAndConditions: termosECondicoes, email: labelEmail, name: labelNome, message: labelMensagem }}
      messages={{ success: mensagemDeSucesso?.mensagemDeSucesso, error: mensagemDeErro?.mensagemDeErro }}
      onSubmit={async (data) => {
        const result = await submit(data)
        return { status: result === 200 ? "processed" : "error" }
      }}
    />
  </div>
)

export default BlockContacts
