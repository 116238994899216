import React from "react"
//
import { ContactsBlock } from "../../../storybook"
import RichText from "../../rich-text"

const BlockContacts = ({ data: { titulo, introducao, contactos, horarioDeAtendimento, mapIframeCode } }) => (
  <div tw="pt-8 lg:pt-10">
    <ContactsBlock
      heading={titulo}
      description={<RichText data={introducao} />}
      contacts1={<RichText data={contactos} />}
      contacts2={<RichText data={horarioDeAtendimento} />}
      mapIframe={typeof mapIframeCode === "object" ? mapIframeCode?.mapIframeCode : mapIframeCode}
    />
  </div>
)

export default BlockContacts
