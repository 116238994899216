import React from "react"
// import "twin.macro"
//
import { SideImageBlock } from "../../../storybook"
import RichText from "../../rich-text"
import { ImageFluid } from "../../../utils/image"
import { NavigationLink } from "../../../utils/navigation"

const BlocksTextAndImage = ({ data: { titulo, texto, variante, imagem, cta, numeros } }) => (
  <div tw="pt-8 lg:pt-10">
    <SideImageBlock
      heading={titulo}
      description={<RichText data={texto} />}
      image={<ImageFluid image={imagem} />}
      cta={<NavigationLink navigation={cta} />}
      statistics={numeros?.map((keyVal) => {
        const pieces = keyVal.split("|")
        return { key: pieces[0], value: pieces[1] || "" }
      })}
      textOnLeft={variante === "Direita"}
    />
  </div>
)

export default BlocksTextAndImage
