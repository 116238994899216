import { getSrc } from "gatsby-plugin-image"

export const getTags = ({ titulo, sumario, imagem, site, canonical = null, robots = null }) => {
  const { seoTitulo, seoSumario, seoImagem } = site

  const image = imagem ? getSrc(imagem) : getSrc(seoImagem)

  const tags = {
    siteName: seoTitulo,
    title: titulo,
    description: sumario?.sumario || seoSumario?.seoSumario || "",
    image: image || null,
    canonical,
    robots
  }

  return tags
}
