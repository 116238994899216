import React from "react"
//
import HeroSideVideo from "../../../storybook/components/hero/HeroSideVideo"
import { ImageFluid } from "../../../utils/image"
import { NavigationLink } from "../../../utils/navigation"

const HeroVideoImage = ({ data: { titulo1, titulo2, videoUrl, imagem, cta, imagemDeFundo, size = null } }) => (
  <HeroSideVideo
    heading1={titulo1}
    heading2={titulo2}
    videoUrl={videoUrl}
    image={<ImageFluid image={imagem} />}
    cta={<NavigationLink navigation={cta} />}
    backgroundImage={<ImageFluid image={imagemDeFundo} alt={titulo1} />}
    size={size}
  />
)

export default HeroVideoImage
