import React from "react"
//
import { InfoGridBlock } from "../../../storybook"
import { NavigationLink } from "../../../utils/navigation"
import { ImageFixed } from "../../../utils/image"
import RichText from "../../rich-text"

const InfoGrid = ({ data: { titulo, prefixo, sumario, blocos, corDeFundo } }) => (
  <div tw="pt-8 lg:pt-10">
    <InfoGridBlock
      heading={titulo}
      subheading={prefixo}
      description={sumario?.sumario}
      solidBg={corDeFundo}
      cards={blocos?.map((block) => {
        return {
          id: block?.id,
          title: block?.titulo,
          description: <RichText data={block?.descricao} />,
          image: <ImageFixed image={block?.imagem} alt={block?.titulo} />,
          cta: <NavigationLink navigation={block?.cta} />
        }
      })}
    />
  </div>
)

export default InfoGrid
