import React from "react"
import { useStaticQuery, graphql } from "gatsby"
//
import { NewsColumn } from "../../storybook/components/blocks/InfoNewsBlock"

export const LatestNews = ({ total = 3 }) => {
  const newsData = useStaticQuery(graphql`
    query {
      allContentfulGlobalBlocoInformativo(filter: { tipo: { eq: "Noticia" } }, sort: { fields: data, order: DESC }) {
        edges {
          node {
            id
            titulo
          }
        }
      }
    }
  `)

  const { allContentfulGlobalBlocoInformativo } = newsData

  const items = allContentfulGlobalBlocoInformativo?.edges
    ?.map((edge) => edge.node)
    .slice(0, total)
    .map((node) => <NewsColumn key={`news-${node.id}`} title={node.titulo} />)

  return items || []
}

export default LatestNews
