import React from "react"
//
import { HeroCenteredContent } from "../../../storybook"
import RichText from "../../rich-text"
import { ImageFluid } from "../../../utils/image"
import { NavigationLink } from "../../../utils/navigation"

const HeroCarousel = ({ data: { slides, imagemDeFundo, cta } }) => {
  const slideItems = slides?.map((slide) => {
    return {
      id: slide.id,
      heading: slide?.titulo,
      subHeading: <RichText data={slide?.subHeading} />
    }
  })

  return (
    <HeroCenteredContent
      slides={slideItems}
      backgroundImage={<ImageFluid image={imagemDeFundo} alt="imagem de fundo" />}
      cta={<NavigationLink navigation={cta} />}
    />
  )
}

export default HeroCarousel
