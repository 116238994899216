import React from "react"
//
import { NewsletterBlock } from "../../../storybook"

const submit = async (data) => {
  const submission = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      ...data
    })
  }

  const result = await fetch(process.env.NEWSLETTER_SUBMISSION_URL, submission)
    .then((response) => response.status)
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e)
    })

  return result
}

const Newsletter = ({
  data: { titulo, informacao, termosECondicoes, labelEmail, labelSubmit, mensagemDeSucesso, mensagemDeErro }
}) => (
  <NewsletterBlock
    heading={titulo}
    description={informacao}
    labels={{ termsAndConditions: termosECondicoes, email: labelEmail, submit: labelSubmit }}
    messages={{ success: mensagemDeSucesso?.mensagemDeSucesso, error: mensagemDeErro?.mensagemDeErro }}
    onSubmit={async (data) => {
      const result = await submit(data)
      return { status: result === 200 ? "processed" : "error" }
    }}
  />
)

export default Newsletter
